import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  connectFirestoreEmulator,
  enableMultiTabIndexedDbPersistence
} from 'firebase/firestore'
import {
  getAuth,
  connectAuthEmulator,
  signInWithCredential,
  EmailAuthProvider
} from 'firebase/auth'

// the values to initialize the firebase app can be found in your firebase project
const firebaseConfig = {
  apiKey: 'AIzaSyAMTn7UgvVSnDz7AtrSTeHf2x2zeq2NsYM',
  authDomain: 'familiae-236e8.firebaseapp.com',
  projectId: 'familiae-236e8',
  storageBucket: 'familiae-236e8.appspot.com',
  messagingSenderId: '598521584525',
  appId: '1:598521584525:web:d39722d7aabc17e446bbc4'
}

// const getAuthInstance = () => {
//   const app = initializeApp(firebaseConfig)
//   const firestore = getFirestore(app)
//   const auth = getAuth(app)
//   return { auth, firestore }
// }

const initFirebase = async () => {
  try {
    const app = initializeApp(firebaseConfig)
    const firestore = getFirestore(app)
    const auth = getAuth(app)
    // const authInstance = getAuthInstance()

    if (process.env.NODE_ENV !== 'production') {
      // connectAuthEmulator(auth, 'http://localhost:9099')
      // connectFirestoreEmulator(firestore, 'localhost', 8080)
      // enableMultiTabIndexedDbPersistence(firestore)
      /**
       * The following code logins the user automatically to speed up development.
       * For this to work you first need to create a user and then run the command
       * yarn emulator:export, then import the data when starting the emulator
       * yarn firebase emulators:start --only firestore,auth --import=firestore_mock_data
       */
      // signInWithCredential(
      //   auth,
      //   EmailAuthProvider.credential('john@doe.com', '123123')
      // )
    }
  } catch (err) {
    console.error(err)
    return err
  }
}

export default initFirebase
