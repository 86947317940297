import { ReactElement } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { useEffect } from 'react'
import { sessionState } from '../state'
import { useRecoilState } from 'recoil'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import routers from '../pages/routes'

type Props = {
  children: ReactElement<any, any>
}
const RequireUnlogged = ({ children }: Props) => {
  const [sessionData, setSession] = useRecoilState(sessionState)
  // const navigate = useNavigate();
  // useEffect(
  //     () =>
  //       onAuthStateChanged(getAuth(), resultUser => {
  //         // signOut(getAuth()); // Force logout
  //         // is user loggedIN?
  //         if (resultUser) {
  //           console.log('I am LOGINED', sessionData)
  //           setSession({
  //             isAuthenticating: true,
  //             user: resultUser && {
  //               uid: resultUser.uid,
  //               email: resultUser.email,
  //               displayName: resultUser.displayName
  //             }
  //           })
  //           navigate(routers.home);
  //         }
  //       }),
  //     [setSession]
  //   )

  return !sessionData.isAuthenticating ? (
    children
  ) : (
    <Navigate to={routers.home} replace />
  )
}

export default RequireUnlogged
