import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

// https://mui.com/customization/theming/#themes

const mainBlackColor = '#121212'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#EAAE1D',
      light: '#ffa733'
    },
    secondary: {
      main: '#e6e6e6',
      light: '#222222'
    },
    text: {
      primary: '#f3f3f3',
      secondary: '#9a9a9a'
    },
    background: {
      paper: mainBlackColor,
      default: mainBlackColor
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: mainBlackColor,
          backgroundImage: 'none',
          boxSizing: 'border-box',
          width: 240
        }
      }
    }
  }
  // typography: {
  //   fontFamily: 'Oswald, Arial'
  // }
})

export default theme
