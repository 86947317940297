/**
 * Define the different parts of your state here
 * using https://recoiljs.org/
 */
import { atom, selector } from 'recoil'
import Database from './types/Database'
import db from './db/db'

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: { setSelf: Function; onSet: Function }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }
    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const sessionState = atom<{
  user:
    | {
        uid: string
        email: string | null
        displayName: string | null | undefined
        avatar: string | null | undefined
      }
    | undefined
    | null
  isAuthenticating: boolean
  role: string | undefined | null
}>({
  key: 'session',
  default: {
    isAuthenticating: false,
    user: null,
    role: null
  },
  effects: [localStorageEffect('user')]
})

// export const toDo = atom<Database.Todo>({
//   key: 'todo',
//   default: {
//     id: "1",
//     description: "Jopa",
//     order: 1,
//     completed: false
//   }
// })

export const Events = atom<Database.Event[]>({
  key: 'Events',
  default: []
  // effects: [localStorageEffect('todos')]
})

export const list = atom<Database.Todo[]>({
  key: 'TodoList',
  default: []
  // effects: [localStorageEffect('todos')]
})

// export const CurrentTodoListQuery = selector({
//   key: 'CurrentTodoList',
//   get: async () => {
//     const response = await db.query({collection: 'todos'})
//     console.log('Selector>>', response);
//     return response;
//   }
// })
