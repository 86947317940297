import { Avatar, Container, Box } from '@mui/material'
import Wrapper from '../components/Wrapper'
import { useSearchParams } from 'react-router-dom'
import ImageLogo from '../components/LogoImage'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

const Resident = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const ResidentList = [
    {
      id: 1,
      name: 'Репьев Антон',
      owner: 'Основатель IT компании A2SEVEN и бизнес-клуба Familiae',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/anton-resident-roja.png',
      state: 'active',
      end_date: '31 Декабря 2024',
      date: new Date('2024-12-31')
    },
    {
      id: 2,
      name: 'Маргарита Садовая',
      owner: 'Логистическая компания',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/resident-margo.png',
      state: 'inactive',
      end_date: '1 Июля 2024',
      date: new Date('2024-07-01')
    },
    {
      id: 3,
      name: 'Борцов Николай',
      owner: 'Производство зерновых и масличных культур. Строительство',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/nikolay-resident-roja.png',
      state: 'active',
      end_date: '28 Августа 2024',
      date: new Date('2024-08-28')
    },
    {
      id: 4,
      name: 'Мищенко Евгений',
      owner:
        'Совладелец компании «Регул» Производство и продажа оборудования сельхозтехники',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/eugene-resident-roja.png',
      state: 'active',
      end_date: '9 Октября 2024',
      date: new Date('2024-10-09')
    },
    {
      id: 5,
      name: 'Кристина Амбарцумян',
      owner: '«Мама испекла» Пекарня-кондитерская',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/kris-resident-roja.png',
      state: 'inactive',
      end_date: '1 Июля 2024',
      date: new Date('2024-07-01')
    },
    {
      id: 6,
      name: 'Андрей Корейво',
      owner: 'Владелец агентства недвижимости «Альфа недвижимость»',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/andreyk-resident-roja.png',
      state: 'inactive',
      end_date: '15 Августа 2024',
      date: new Date('2024-09-15')
    },
    {
      id: 7,
      name: 'Марина Назаренко',
      owner: 'Владелец IT агентства «Ирбис Тех»',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/marina-resident-roja.png',
      state: 'active',
      end_date: '1 Сентября 2024',
      date: new Date('2024-09-01')
    },
    {
      id: 8,
      name: 'Садовой Андрей',
      owner:
        'Основатель Richmark. Производства автомобильных аксессуаров и изделий из ЭВА и эко-кожи',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/andrey-resident-roja.png',
      state: 'active',
      end_date: '27 Сентября 2024',
      date: new Date('2024-09-27')
    },
    {
      id: 9,
      name: 'Арсен Амбарцумян',
      owner:
        '«Город детства» Производство детских площадок и металлоконструкций',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/arsen-resident-roja.png',
      state: 'inactive',
      end_date: '1 Июля 2024',
      date: new Date('2024-07-01')
    },
    {
      id: 10,
      name: 'Дмитрий Сергеев',
      owner: 'Сооснователь сельхоз-маркетплейса «Точно маркет»',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/resident-dimas.png',
      state: 'active',
      end_date: '9 Октября 2024',
      date: new Date('2024-10-09')
    },
    {
      id: 12,
      name: 'Иванас Ольга',
      owner: 'Владелец производства',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/olga-resident-roja.png',
      state: 'inactive',
      end_date: '1 Сентября 2024',
      date: new Date('2024-09-01')
    },
    {
      id: 11,
      name: 'Пополитова Нина',
      owner: 'Представитель семейного бизнеса. Ресторан Альбатрос. Производство мороженого. Ювелирные украшения',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/resident-roja-%D0%BD%D0%B8%D0%BD%D0%B0.png',
      state: 'active',
      end_date: '14 Ноября 2024',
      date: new Date('2024-11-14')
    },
    {
      id: 13,
      name: 'Никита Гусаков',
      owner: 'Владелец веб-студии WebAim. Разработка интернет-магзаинов, интеграция CRM, корпоративные сайты',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/resident-roja-%D0%BD%D0%B8%D0%BA%D0%B8%D1%82%D0%B0.png',
      state: 'active',
      end_date: '9 Декабря 2024',
      date: new Date('2024-12-09')
    },
    {
      id: 14,
      name: 'Игорь Кацевал',
      owner: 'Логистика, перевозки из Китая',
      image:
        'https://storage.yandexcloud.net/familiae/Avatars/resident-roja-%D0%B8%D0%B3%D0%BE%D1%80%D1%8C.png',
      state: 'active',
      end_date: '9 Октября 2024',
      date: new Date('2024-10-09')
    }
  ]

  const resident = ResidentList.find(
    ({ id }) => id === parseInt(searchParams.get('id') || '')
  )

  const checkDate = (resident: any) => {
    const today = new Date()
    return today < resident.date
  }

  return (
    <>
      <Wrapper>
        <Container maxWidth='xs'>
          <Box display='flex' justifyContent='center'>
            <Box width={200}>
              {' '}
              <a href='https://familiae.ru'>
                <ImageLogo />
              </a>{' '}
            </Box>
          </Box>
          {resident ? (
            <Box
              p={5}
              display='flex'
              justifyContent='center'
              flexDirection='column'
              alignItems='center'
            >
              <Avatar
                style={{ width: 200, height: 200 }}
                alt='Remy Sharp'
                src={resident?.image}
              />
              <Box textAlign='center'>
                <h2>{resident?.name}</h2>
                <p style={{ color: '#9A9A9A' }}>{resident?.owner}</p>
              </Box>
              <Box
                width='100%'
                borderRadius={2}
                textAlign='center'
                style={{ backgroundColor: '#212121' }}
              >
                {checkDate(resident) ? (
                  <>
                    <p style={{ marginBottom: 0 }}>
                      Статус:{' '}
                      <strong style={{ color: '#3EA12E' }}>Активный</strong>
                    </p>
                    <p style={{ marginTop: 0, color: '#6D6D6D' }}>
                      до {resident?.end_date}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Статус:{' '}
                      <strong style={{ color: '#CFB00D' }}>Неактивный</strong>
                    </p>
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Box
                p={5}
                display='flex'
                flexDirection='column'
                alignContent='center'
                justifyContent='center'
                textAlign='center'
              >
                <Avatar
                  variant='square'
                  style={{ width: 250, height: 250 }}
                  alt='Remy Sharp'
                  src='https://storage.yandexcloud.net/familiae/Avatars/no-resident-found.png'
                />
                <p>Такого резидента еще нет в базе</p>
              </Box>
            </>
          )}
        </Container>
      </Wrapper>
    </>
  )
}

export default Resident
