import { useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router'
import Signin from './Signin'
// import Home from './Home'
import Resident from './Resident'
import routes from './routes'
import RequireAuth from '../components/RequireAuth'
import RequireUnlogged from '../components/RequireUnlogged'
import { Container, Box } from '@mui/material'
import ImageLogo from '../components/LogoImage'
import { keyframes } from '@mui/system'

const flareAnimation = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`

const App = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  return isLoading ? (
    <Container maxWidth='sm'>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '200px',
          // animationDelay: '1s',
          transform: 'skewX(0deg)',
          left: '-100px',
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.1))',
          animation: `${flareAnimation} 2s infinite ease-in-out`
        }}
      ></Box>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          alignItems: 'center'
        }}
      >
        <ImageLogo />
      </Box>
    </Container>
  ) : (
    <Routes>
      <Route
        path={routes.signin}
        element={
          <RequireUnlogged>
            <Signin />
          </RequireUnlogged>
        }
      />
      <Route
        path={routes.home}
        element={
          // <RequireAuth>
          <Resident />
          // </RequireAuth>
        }
      />
      <Route path='*' element={<Navigate to={routes.home} />} />
    </Routes>
  )
}

export default App
