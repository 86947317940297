import {
  Typography,
  TextField,
  Button,
  Container,
  Box,
  Alert
} from '@mui/material'
import AppBar from '../components/AppBar'
import routes from './routes'
import Wrapper from '../components/Wrapper'
import formErrorMessages from '../utils/formErrorMessages'
import { FormTextInput } from '../types/Optional'
import { useForm } from 'react-hook-form'
import ImageLogo from '../components/LogoImage'
import { getAuth, signInWithCredential, EmailAuthProvider } from 'firebase/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import routers from '../pages/routes'

const Signin = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormTextInput>()

  const navigate = useNavigate()
  const [isError, setLoginError] = useState(false)

  const Login = async (vals: FormTextInput) => {
    try {
      const auth = getAuth()
      const user = await signInWithCredential(
        auth,
        EmailAuthProvider.credential(vals.name, vals.password)
      )
      console.log('SUCCESSS', user)
      navigate(routers.home)
    } catch (err) {
      console.log('JOPAAAA', err)
      setLoginError(true)
      return err
    }
    // const item = {
    //   description: vals.name,
    //   order: todos.length,
    //   completed: false
    // }
    // const res = (await db.addItem('todos', item)) as Database.Todo
    // setTodos(oldtodos => [...oldtodos, res])
  }

  return (
    <>
      <Wrapper>
        <Container maxWidth='xs'>
          <form
            onSubmit={handleSubmit(vals => {
              Login(vals)
              // reset()
            })}
          >
            <Box sx={{ pt: 20 }}>
              <ImageLogo />
              {isError ? (
                <Box sx={{ py: 2 }}>
                  <Alert severity='error'>
                    Ошибка при вводе данных, либо пользователь не существует
                  </Alert>
                </Box>
              ) : null}
              <TextField
                sx={{ mb: 2 }}
                label='Логин'
                variant='standard'
                fullWidth
                {...register('name', {
                  required: formErrorMessages.required
                })}
                error={!!errors.name}
                helperText={errors.name?.message || ' '}
              />
              <TextField
                sx={{ mb: 2 }}
                label='Пароль'
                type='password'
                variant='standard'
                fullWidth
                {...register('password', {
                  required: formErrorMessages.required
                })}
                error={!!errors.password}
                helperText={errors.password?.message || ' '}
              />

              <Button fullWidth variant='contained' size='large' type='submit'>
                ЗАЙТИ В ЛИЧНЫЙ КАБИНЕТ
              </Button>
            </Box>
          </form>
        </Container>
      </Wrapper>
    </>
  )
}

export default Signin
